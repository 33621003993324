import React, { useContext, useEffect } from "react";
import { isMobilePredicate } from "../../utils";
import TrackingContext from "../../context/TrackingContext";

function Avantis({ mobileId, desktopId }) {
  const domain = process.env.GATSBY_SHORT_DOMAIN;
  const { utm_source } = useContext(TrackingContext);
  
  useEffect(() => {
    const isMobile = isMobilePredicate();
  
    let tagid = mobileId;
    if (!isMobile) {
      tagid = desktopId;
    }
    // Avantis has different ID on IG
    const avantisSource = `
    https://cdn.avantisvideo.com/avm/js/video-loader.js?id=13d84a2b-9415-4f41-8cff-79dad5d6cbfb&tagId=${tagid}&subId=${utm_source}&=${domain}&callback=`;
    const script = document.createElement("script");
    script.id = "avantisJS";
    script.src = avantisSource;
    const avantis = document.getElementById("avantisContainerJS");
    avantis.appendChild(script);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div id="avantisContainerJS" className="avantis" />;
}

export default Avantis;