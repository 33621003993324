import { useContext } from "react";
import TrackingContext from "../context/TrackingContext";
import { isBrowser, loadScript } from "../utils";

const useTaboola = (page) => {
  const { utm_source } = useContext(TrackingContext);

  const setupTaboola = () => {
    // Load id.js
    loadScript("https://cdn.taboola.com/webpush/id.js", true, false, false);

    // Ad global variables
    loadScript(
      "",
      true,
      false,
      false,
      `
        var actCode = "${process.env.GATSBY_TABOOLA_ACTIVATION_KEY}";
        var apiKey = "${process.env.GATSBY_TABOOLA_PUBLIC_KEY}";
        var partnerName = "${process.env.GATSBY_TABOOLA_PARTNER_NAME}";
        `
    );

    // Load push.js
    loadScript("https://cdn.taboola.com/webpush/push.js", true, false, false);
  };
  return {
    setupTaboola:
    isBrowser() && page === 1 && ( utm_source && !utm_source.includes("fb-") )
        ? setupTaboola
        : () => {},
  };
};

export default useTaboola;
